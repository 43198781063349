import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faGitlab } from "@fortawesome/free-brands-svg-icons";
import Profilbild from "../images/Profilbild.jpg";
import HeaderData from "../data/HeaderData.json";
function HeaderContainer() {
  return (
    <div className={"header-container"}>
      <h3>Me</h3>
      <div className={"header"}>
        <div className="header-row">
          <div>
            <h1>{HeaderData.name}</h1>
            <h2
              style={{ whiteSpace: "pre" }}
            >{`Fachinformatiker-\nAnwendungsentwicklung`}</h2>
            <div className={"icons"}>
              <a href={HeaderData.github}>
                <FontAwesomeIcon icon={faGithub} />
              </a>
              <a href={HeaderData.gitlab}>
                <FontAwesomeIcon icon={faGitlab} />
              </a>
            </div>
          </div>
        </div>
        <div className="header-row">
          <img src={Profilbild} alt={"Profilbild"} />
        </div>
      </div>
    </div>
  );
}
export default HeaderContainer;
