import React from "react";
import ContactForm from "./contact/ContactForm";

function ContactContainer() {
  return (
    <div className={"contact-container"}>
      <h3>Contact</h3>
      <ContactForm />
    </div>
  );
}
export default ContactContainer;
