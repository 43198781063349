import { Chrono } from "react-chrono";

function WorkTimelineContainer() {
  let schoolItems = [
    {
      title: "2011 August - 2014 Juli",
      cardTitle: "Berufskolleg für Technik und Informatik",
      cardDetailedText: "Abschluss Informationstechnischer Assistent",
    },
    {
      title: "2014 August - 2017 Juli",
      cardTitle: "Georg-Simon-Ohm-Berufskolleg",
      cardDetailedText: "Abschluss Fachinformatiker Anwendungsentwicklung",
    },
    {
      title: "Seit 2017 Oktober",
      cardTitle: "Hochschule Düsseldorf",
      cardDetailedText: "Bachelor of Science Medieninformatik",
    },
  ];

  let workItems = [
    {
      title: "2013 August - 2013 Oktober",
      cardTitle: "Sparkasse Neuss",
      cardDetailedText: "Informationstechnischer Assistent",
    },
    {
      title: "2014 August - 2017 Juli",
      cardTitle: "Koopmann Online Medien UG",
      cardDetailedText: "Ausbildung Fachinformatiker Anwendungsentwicklung",
    },
    {
      title: "2019 März - 2019 August",
      cardTitle: "Hochschule Düsseldorf",
      cardDetailedText: "Studentische Hilfskraft für Datenbanksysteme",
    },
    {
      title: "2020 Oktober - 2023 Februar",
      cardTitle: "Hochschule Düsseldorf",
      cardDetailedText: "Studentische Hilfskraft für Webprogrammierung",
    }
  ];

  return (
    <div className={"worktimeline-container"}>
      <h3>Timeline</h3>
      <div className={"worktimeline"}>
        <h4>School Timeline</h4>
        <Chrono
          items={schoolItems}
          hideControls={true}
          activeItemIndex={-1}
          disableAutoScrollOnClick={true}
          disableClickOnCircle={true}
          disableNavOnKey={true}
        />
        <h4>Work Timeline</h4>
        <Chrono
          items={workItems}
          hideControls={true}
          activeItemIndex={-1}
          disableAutoScrollOnClick={true}
          disableClickOnCircle={true}
          disableNavOnKey={true}
        />
      </div>
    </div>
  );
}
export default WorkTimelineContainer;
