import React from "react";
import SkillItem from "./skill/SkillItem";
import SkillData from "../data/SkillData.json";
import { useTranslation } from "react-i18next";

function SkillsContainer() {
  const { t } = useTranslation();
  return (
    <div className={"skills-container"}>
      <h3>{t("Skills")}</h3>
      <div className={"skills"}>
        <div>
          <h4>Frontend</h4>
          <div className={"skills-frontend"}>
            {SkillData.frontend.map((skill, skillKey) => {
              return (
                <SkillItem
                  key={skillKey}
                  skill={skill.skill}
                  level={skill.level}
                />
              );
            })}
          </div>
        </div>
        <div>
          <h4>Backend</h4>
          <div className={"skills-backend"}>
            {SkillData.backend.map((skill, skillKey) => {
              return (
                <SkillItem
                  key={skillKey}
                  skill={skill.skill}
                  level={skill.level}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
export default SkillsContainer;
