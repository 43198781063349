import React from "react";

function SkillItem({ skillKey, skill, level }) {
  return (
    <div key={skillKey} className={"skill-item"}>
      <div>{skill}</div>
      <div>
        <small>{level}</small>
      </div>
    </div>
  );
}
export default SkillItem;
