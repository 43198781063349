import React from "react";
import ProjectCard from "./project/ProjectCard";
import ProjectData from "../data/ProjectData.json";

function ProjectsContainer() {
  return (
    <div className={"projects-container"}>
      <h3>Projects</h3>
      <div className={"projects"}>
        {ProjectData.map((project, projectKey) => {
          return (
            <ProjectCard
              key={projectKey}
              name={(projectKey !== 0 ? project.name : `${project.name} (NEW)`)}
              source={project.source}
              website={project.website}
              description={project.description}
              tags={project.tags}
            />
          );
        })}
      </div>
    </div>
  );
}
export default ProjectsContainer;
