import "./App.css";
import React from "react";
import HeaderContainer from "./container/HeaderContainer";
import ContactContainer from "./container/ContactContainer";
import ProjectsContainer from "./container/ProjectsContainer";
import SkillsContainer from "./container/SkillsContainer";
import WorkTimelineContainer from "./container/WorkTimelineContainer";

function App() {
  return (
    <div className="index">
      <HeaderContainer />
      <SkillsContainer />
      <ProjectsContainer />
      <WorkTimelineContainer />
      <ContactContainer />
    </div>
  );
}

export default App;
