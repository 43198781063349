import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGitAlt } from "@fortawesome/free-brands-svg-icons";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import ProjectTag from "./ProjectTag";

function ProjectCard({ name, source, website, description, tags }) {
  return (
    <div className={"project-card"}>
      <div className={"project-card-header"}>
        <h4>{name}</h4>
      </div>
      <div className={"project-card-links divider"}>
        <a href={website}>
          <div className={"project-card-link"}>
            <FontAwesomeIcon icon={faGlobe} />
            <span>Check Website</span>
          </div>
        </a>
        <a href={source}>
          <div className={"project-card-link"}>
            <FontAwesomeIcon icon={faGitAlt} />
            <span>Check Source</span>
          </div>
        </a>
      </div>
      <div className={"project-card-description divider"}>
        <small>Descirption</small>
        <span>{description}</span>
      </div>
      <div className={"project-card-tags"}>
        <small>Tags</small>
        <div className={"project-card-tag-container"}>
          {tags.map((tag, tagKey) => {
            return <ProjectTag key={tagKey} tag={tag} />;
          })}
        </div>
      </div>
    </div>
  );
}
export default ProjectCard;
